/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';

/*body {
  margin-bottom: 273px;
  background-color: #f7f7f7;
}*/

.testButton {
    border: 3px solid var(--primary-color) !important;
    background-color: white !important;
    color: var(--primary-color) !important;
    border-radius: var(--button--border-radius);
    cursor: pointer;
    font-weight: var(--button--font-weight);
    font-family: var(--button--font-family);
    font-size: var(--button--font-size);
    line-height: var(--button--line-height);
    padding: var(--button--padding-vertical) var(--button--padding-horizontal);
    text-decoration: none;
    margin: auto;
    text-decoration: none !important;
    text-transform: uppercase;
    position: absolute;
    right: 0;
    width: -webkit-fill-available;
    text-align: center;
}

.mt--6, .my--6 {
  margin-top: -4.5rem !important;
}

@media (max-width: 576px) {
  /* --breakpoint-sm: 576px;*/
  .nocontainer-sm {
    padding: 0px;
  }
}

.containerWHF{
  background-color: white;
  min-height: 10vh;
}

/* Provide sufficient contrast against white background */

#circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
}

.loader {
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  border: 8px solid #f5f5f5;
  border-top: 8px solid var(--primary-color);
  border-radius: 50%;
  animation: rotate 5s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.fixed {
  position: sticky;
  position: -webkit-sticky;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  top: 0;
  z-index: 999;
  color: black;
  background-color: white;
}

